angular.module('fingerink')
    .directive('sendInsertFirmantes', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'web/main/send/steps/insertSigners/sendInsertFirmantes.tpl.html',
            scope: {
                sign: '=',
                parent: '=',
                createTemplate: '='
            },

            controllerAs: 'controller',
            controller: 'sendInsertFirmantesCtrl'
        };
    })
    .controller('sendInsertFirmantesCtrl', function ($scope, $mdDateLocale, templateService, contactService, signatureService, session, $translate, selectContactModal, selectSendTypeModal, $window) {
        var that = this;
        that.sign = $scope.sign;
        that.createTemplate = $scope.createTemplate;
        that.parent = $scope.parent;
        that.user = session.get().user;
        $mdDateLocale.firstDayOfWeek = 1;
        that.showDialogFirmantesMostrado = false;
        that.date = new Date();
        that.dateMonth = moment().add(1, 'M').toDate();
        that.sign.expiration = moment().add(1, 'M').toDate();
        contactService.getContactoss().then(response => that.contacts = response.data);
        $mdDateLocale.formatDate = date => moment(date).format('DD-MM-YYYY');
        if (that.sign.template && that.sign.template.id) {
            templateService.getURL(that.sign.template.id, 0).then(response => that.urlImage = response.data);
        } else {
            signatureService.getURL(that.sign.id, 0).then(response => that.urlImage = response.data);
        }

        function checkCorrect() {
            if (!that.sign.subject) {
                that.showErrors = true;
                return false;
            }
            if (that.sign.signers) {
                for (var i = 0; i < that.sign.signers.length; i++) {
                    if (!that.sign.signers[i].name || (!that.sign.signers[i].signatureEmail && !that.createTemplate)) {
                        that.showErrors = true;
                        return false;
                    }
                }
            }
            return true;
        }
        that.siguiente = function () {
            if (checkCorrect()) {
                that.parent.siguiente();
            }
        };
        that.toPrepare = function () {
            if (checkCorrect()) {
                that.parent.step = 'prepareDocument';
            }
            $window.scrollTo(0, 0);
        };
        /*
                that.anterior = function () {
                    that.parent.anterior();
                };
        */

        if (!that.sign.sendType) {
            if (that.sign.type == 'Email') {
                that.sign.sendType = 'MultipleDocument';
            } else {
                that.sign.sendType = 'OneDocument';
            }
        }







        that.add = function () {
            if (!that.sign.signers) {
                that.sign.signers = [];
            }
            if (that.sign.signers.length == 1  && that.sign.type != 'Email' && !that.createTemplate) {
                that.showDialogFirmantes();
                that.showDialogFirmantesMostrado = true;
            }
            that.sign.signers.push({
                name: '',
                signType: 'SEND',
                signatureEmail: ''
            });
        };

        that.selectGroup = function () {
            selectContactModal.openModal().result.then(function (result) {
                if (result && result.length > 0 && that.sign.signers.length == 1 && !that.sign.signers[0].name && !that.sign.signers[0].signatureEmail) {
                    that.sign.signers = [];
                }
                if (!that.sign.signers) {
                    that.sign.signers = [];
                }
                if (that.sign.signers.length < 2  && that.sign.type != 'Email' && !that.createTemplate && result && result.length > 0) {
                    that.showDialogFirmantes();
                    that.showDialogFirmantesMostrado = true;
                }
                if (result) {
                    for (var i = 0; i < result.length; i++) {
                        that.sign.signers.push({
                            name: result[i].name,
                            signType: 'SEND',
                            signatureEmail: result[i].email
                        });
                    }
                }
            });
        };

        that.rellenarContacto = function (c, f) {
            f.name = c.name;
            f.signatureEmail = c.email;
        };


        if (that.sign.tempalte && that.sign.tempalte.id) {
            templateService.getPlantilla(that.sign.template.id).then(function (response) {
                that.sign.tempalte = response.data;
                that.sign.subject = that.sign.template.subject;
                that.sign.text = that.sign.template.text;
                that.sign.type = that.sign.template.type;
                that.sign.pages = that.sign.template.pages;
                that.sign.signers = that.sign.template.signers;
                that.sign.documentFields = that.sign.template.documentFields;
            });
        }




        if (!that.sign.signers) {
            if (that.sign.template) {
                that.sign.firmantes = that.sign.template.signers;
            } else {
                if (that.sign.type != 'SelfSigning') {
                    that.add();
                }
            }
        }




        that.showDialogFirmantes = function () {

            if (that.sign.type != 'Email' && that.sign.type != 'InPerson' && that.sign.type != 'SelfSigning' && that.sign.type != 'Fast' && !that.createTemplate) {
                selectSendTypeModal.openModal().result.then(result => that.sign.sendType = result);
            }
        };

    });